<template>
  <b10-base>
    <v-text-field
      v-model="form.nombre"
      clearable
      flat
      label="Nombre"
      :rules="formRules.nombre"
    />
    <b10-autocomplete
      v-model="form.idttelefono"
      :items="ttelefonos"
      item-value="ttelefono.idttelefono"
      item-text="ttelefono.descripcion"
      label="Tipo de contacto"
      clearable
      :rules="formRules.idttelefono"
    />
    <v-text-field
      v-model="form.telefono"
      clearable
      flat
      label="Teléfono"
      :rules="formRules.telefono"
    />
    <v-text-field
      v-model="form.email"
      clearable
      flat
      label="Correo electrónico"
      :rules="formRules.email"
    />
    <v-text-field
      v-model="form.horario"
      clearable
      flat
      label="Horario"
    />
    <b10-subheader
      label="Para notificaciones de "
      :icon="$vuetify.icons.values.send"
    />
    <v-checkbox
      v-model="form.email_notifica_sat"
      label="Servicio técnico"
    />
    <v-checkbox
      v-model="form.email_notifica_comercial"
      label="Comerciales"
    />
    <v-checkbox
      v-model="form.email_notifica_admon"
      label="Administración"
    />
    <template
      v-if="idsistema"
    >
      <b10-subheader
        v-if="(isInsert || $offline.db.isOfflinePk(id)) && (hasPermVerPersonasContactoCodigoCRA || hasPermVerPersonasContactoCodigoCoaccionCRA)"
        label="Datos de CRA"
        :icon="$vuetify.icons.values.novedad"
      />
      <v-text-field
        v-if="(isInsert || $offline.db.isOfflinePk(id))"
        v-model.number="form.orden_cra"
        clearable
        flat
        label="Orden CRA"
        type="number"
      />
      <v-text-field
        v-if="(isInsert || $offline.db.isOfflinePk(id)) && hasPermVerPersonasContactoCodigoCRA"
        v-model="form.codigo_cra"
        flat
        label="Código CRA"
        :append-icon="isInsert ? null : showingCodigoCRA ? $vuetify.icons.values.hide : $vuetify.icons.values.show"
        :type="showingCodigoCRA ? 'text' : 'password'"
        @click:append="clickShowingCodigoCRA"
      />
      <v-text-field
        v-if="(isInsert || $offline.db.isOfflinePk(id)) && hasPermVerPersonasContactoCodigoCoaccionCRA"
        v-model="form.codigo_coaccion_cra"
        flat
        label="Código de coacción CRA"
        :append-icon="isInsert ? null : showingCodigoCoaccionCRA ? $vuetify.icons.values.hide : $vuetify.icons.values.show"
        :type="showingCodigoCoaccionCRA ? 'text' : 'password'"
        @click:append="clickShowingCodigoCoaccionCRA"
      />
    </template>
    <b10-subheader
      label="Otros datos"
      :icon="$vuetify.icons.values.info"
    />
    <v-checkbox
      v-if="(isInsert || $offline.db.isOfflinePk(id)) && hasPermPersonaContactoParaTodosLosSistemas"
      v-model="form.para_todos_los_sistemas"
      label="Para todos los sistemas."
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ClienteTelefonoFormData'
import { regexEmail } from '@/utils/regex'
import { PARAMETRO } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    idsistema: {
      type: [Number, String],
      default: '',
    },
    hasPermVerPersonasContactoCodigoCRA: {
      type: Boolean,
      default: false,
    },
    hasPermVerPersonasContactoCodigoCoaccionCRA: {
      type: Boolean,
      default: false,
    },
    hasPermPersonaContactoParaTodosLosSistemas: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        nombre: null,
        idttelefono: null,
        telefono: null,
        email: null,
        horario: null,
        email_notifica_sat: true,
        email_notifica_comercial: false,
        email_notifica_admon: false,
        orden_cra: null,
        codigo_cra: null,
        codigo_coaccion_cra: null,
        observaciones: null,
        para_todos_los_sistemas: false,
      },
      formRules: {
        nombre: [
          v => !!v || 'Campo requerido'
        ],
        idttelefono: [
          v => !!v || 'Campo requerido'
        ],
        email: [
          v => !v || regexEmail.test(v) || 'El correo electrónico no es válido',
        ],
      },
      ttelefonos: [],
      showingCodigoCRA: false,
      showingCodigoCoaccionCRA: false,
    }
  },
  async created () {
    this.showingCodigoCRA = this.isInsert
    this.showingCodigoCoaccionCRA = this.isInsert
    this.ttelefonos = await Data.selectTtelefono(this)
    if (this.isInsert) {
      const tipoTelefonoDefecto = (
        await this.$offline.parametro.valorParametro(PARAMETRO.parametros.TIPO_TELEFONO_DEFECTO)
      )
      if (tipoTelefonoDefecto) {
        this.$set(this.form, 'idttelefono', parseInt(tipoTelefonoDefecto))
      }
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id, this.idsistema)
      this.$set(this.form, 'nombre', formData.cliente_telefono.nombre)
      this.$set(this.form, 'idttelefono', formData.cliente_telefono.idttelefono)
      this.$set(this.form, 'telefono', formData.cliente_telefono.telefono)
      this.$set(this.form, 'email', formData.cliente_telefono.email)
      this.$set(this.form, 'horario', formData.cliente_telefono.horario)
      this.$set(this.form, 'email_notifica_sat', formData.cliente_telefono.email_notifica_sat)
      this.$set(this.form, 'email_notifica_comercial', formData.cliente_telefono.email_notifica_comercial)
      this.$set(this.form, 'email_notifica_admon', formData.cliente_telefono.email_notifica_admon)
      if (this.idsistema) {
        this.$set(this.form, 'orden_cra', formData.sistema_telefono.orden_cra)
        this.$set(this.form, 'codigo_cra', formData.sistema_telefono.codigo_cra)
        this.$set(this.form, 'codigo_coaccion_cra', formData.sistema_telefono.codigo_coaccion_cra)
      }
      this.$set(this.form, 'observaciones', formData.cliente_telefono.observaciones)
      this.$set(this.form, 'para_todos_los_sistemas', formData.cliente_telefono.para_todos_los_sistemas)
    },
    async clickShowingCodigoCRA () {
      if (this.isEdit) {
        this.showingCodigoCRA = !this.showingCodigoCRA
      }
    },
    async clickShowingCodigoCoaccionCRA () {
      if (this.isEdit) {
        this.showingCodigoCoaccionCRA = !this.showingCodigoCoaccionCRA
      }
    },
  }
}
</script>
